

































import { VesselTypeModel } from "@/api/generated";
import { DataTableHeader } from "vuetify";
import { Vue, Component, Prop } from "vue-property-decorator";
import nameof from "@/utility/nameof";
import api from "@/api";
import SnackbarModule from "@/store/snackbarModule";
import { getModule } from "vuex-module-decorators";
import Discriminator from "@/types/Discriminator";
import getContractType from '../../../utility/getContractType';

const snackbarModule = getModule(SnackbarModule);

@Component
export default class VesselTypeTable extends Vue {
  @Prop({ default: Discriminator.VESSELS }) discriminator!: Discriminator;
  @Prop({ default: "Vessel Types" }) tableTitle!: string;
  
  public loading = false;
  public vesselTypes: Array<VesselTypeModel> = [];
  public headers: Array<DataTableHeader<VesselTypeModel>> = [
    { text: "", value: "actions", sortable: false },
    { text: "Name", value: nameof<VesselTypeModel>("name") },
    {
      text: "Created By",
      value: nameof<VesselTypeModel>("createdByUserFullname")
    },
    { text: "Created", value: nameof<VesselTypeModel>("createdUtc") }
  ];

  public async created() {
    await this.getVesselTypes();
  }

  public async getVesselTypes() {
    try {
      this.loading = true;
      const response = await api.VesselTypeService.apiVesseltypeDiscriminatorGet(getContractType(this.discriminator));
      this.vesselTypes = response.data;
    } catch {
      snackbarModule.setSnackbarMessage("Failed to fetch vessel types");
    } finally {
      this.loading = false;
    }
  }
}
