































import { Component, Watch, PropSync, Ref, Prop } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import { VForm } from "@/types/vForm";
import Validation from "@/mixins/validation";
import Api from "@/api";
import SnackbarModule from "@/store/snackbarModule";
import { VesselTypeUpsertModel } from "@/api/generated";
import AgencyAutocomplete from "@/components/common/agency/AgencyAutocomplete.vue";
import Discriminator from '@/types/Discriminator';
import { ContractType } from "@/api/generated";
import getContractType from '../../utility/getContractType';

const snackbarModule = getModule(SnackbarModule);

@Component({ components: { AgencyAutocomplete } })
export default class VesselTypeCreateDialog extends Validation {
  @Ref() private form!: VForm;
  @PropSync("dialog") private syncedDialog!: boolean;
  @Prop({ default: Discriminator.VESSELS }) discriminator!: Discriminator;
  @Prop(String) vesselTypeId!: string;
  @Prop({default: "Vessel Type"}) modalTitle!: string;

  private loading = false;
  private vesselType: VesselTypeUpsertModel = {
    name: "",
    discriminator: getContractType(this.discriminator)
  };

  @Watch("vesselTypeId", { immediate: true })
  private async onVesselIdChanged() {
    if (!this.vesselTypeId) {
      return;
    }

    const response = await Api.VesselTypeService.apiVesseltypeVesselTypeIdGet(
      this.vesselTypeId
    );

    this.vesselType = response.data;
  }

  private async onVesselTypeUpdate() {
    if (!this.form.validate()) {
      return;
    }

    try {
      this.loading = true;
      await Api.VesselTypeService.apiVesseltypeVesselTypeIdPut(
        this.vesselTypeId,
        this.vesselType
      );
      snackbarModule.setSnackbarMessage("Vessel type updated");
      this.$emit("vessel-type-updated");
    } catch {
      snackbarModule.setSnackbarMessage("Failed to update vessel type");
    } finally {
      this.loading = false;
      this.syncedDialog = false;
    }
  }
}
