































import { Component, Watch, PropSync, Ref, Prop } from 'vue-property-decorator';
import { getModule } from "vuex-module-decorators";
import { VForm } from "@/types/vForm";
import Validation from "@/mixins/validation";
import Api from "@/api";
import SnackbarModule from "@/store/snackbarModule";
import { VesselTypeModel } from "@/api/generated";
import Discriminator from '../../types/Discriminator';
import getContractType from '../../utility/getContractType';

const snackbarModule = getModule(SnackbarModule);

@Component
export default class VesselTypeCreateDialog extends Validation {
  @Ref() private form!: VForm;
  @PropSync("dialog") private syncedDialog!: boolean;
  @Prop({ default: Discriminator.VESSELS }) discriminator!: Discriminator;
  @Prop({default: "Vessel Type"}) modalTitle!: string;

  private loading = false;
  private vesselType: VesselTypeModel = {
    name: "",
    discriminator: getContractType(this.discriminator)
  };

  @Watch("syncedDialog")
  private onSyncedDialogChanged() {
    if (!this.syncedDialog) {
      return;
    }

    this.form?.reset();
  }

  private async onVesselTypeCreate() {
    if (!this.form.validate()) {
      return;
    }

    try {
      this.loading = true;
      await Api.VesselTypeService.apiVesseltypePost(this.vesselType);
      snackbarModule.setSnackbarMessage("Vessel type created");
      this.$emit("vessel-type-created");
    } catch {
      snackbarModule.setSnackbarMessage("Failed to create vessel type");
    } finally {
      this.loading = false;
      this.syncedDialog = false;
    }
  }
}
