




























import { Vue, Component, Prop } from "vue-property-decorator";
import VesselTypeTable from "@/components/vessel/type/VesselTypeTable.vue";
import VesselTypeCreateDialog from "@/components/dialogs/VesselTypeCreateDialog.vue";
import VesselTypeUpdateDialog from "@/components/dialogs/VesselTypeUpdateDialog.vue";
import api from "@/api";
import { VesselTypeModel } from "@/api/generated";
import { getModule } from "vuex-module-decorators";
import SnackbarModule from "@/store/snackbarModule";
import Discriminator from '@/types/Discriminator';

const snackbarModule = getModule(SnackbarModule);

@Component({
  components: {
    VesselTypeTable,
    VesselTypeCreateDialog,
    VesselTypeUpdateDialog
  }
})
export default class VesselTypeOverview extends Vue {
  @Prop({ default: Discriminator.VESSELS }) discriminator!: Discriminator;
  @Prop({default: "Vessel Types"}) tableTitle!: string;
  @Prop({default: "Vessel Type"}) modalTitle!: string;

  public tableRefreshKey = false;
  public createVesselType = false;
  public updateVesselType = false;
  public updateVesselTypeId = "";

  public onCreateVesselType() {
    this.createVesselType = true;
  }

  public onUpdateVesselType(vesselType: VesselTypeModel) {
    if (!vesselType.vesselTypeId) {
      return;
    }

    this.updateVesselTypeId = vesselType.vesselTypeId;
    this.updateVesselType = true;
  }

  public onVesselTypeCreated() {
    this.tableRefreshKey = !this.tableRefreshKey;
  }

  public onVesselTypeUpdated() {
    this.tableRefreshKey = !this.tableRefreshKey;
  }

  public async onVesselTypeDeleted(vesselType: VesselTypeModel) {
    if (!vesselType.vesselTypeId) {
      return;
    }

    try {
      await api.VesselTypeService.apiVesseltypeVesselTypeIdDelete(
        vesselType.vesselTypeId
      );
      snackbarModule.setSnackbarMessage("Vessel type deleted");
    } catch {
      snackbarModule.setSnackbarMessage("Failed to delete vessel type");
    } finally {
      this.tableRefreshKey = !this.tableRefreshKey;
    }
  }
}
